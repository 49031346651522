import React, { useState } from "react";
import styles from "./AppEntryUserMain.module.scss";
import CompanyImg from "../../assets/images/companyLogo.png";
import OpenDoorImg from "../../assets/images/openDoor.png";
import CloseDoorImg from "../../assets/images/closeDoor.png";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import { useQuery } from "react-query";

const fetchentrySwitch = async () => {
  const { data } = await axios.get("/accessEntryUser");

  return data;
};

const AppEntryUserMain = () => {
  const [selectedSwitch, setSelectedSwitch] = useState(null);
  const [page, setPage] = useState(5);

  const {
    data: entrySwitch,
    isLoading,
    isError,
  } = useQuery("entrySwitch", fetchentrySwitch);

  const handleMoreData = () => {
    if (selectedSwitch?.logs?.length > page) {
      setPage(page + 5);
    }
  };

  const handleWorkStart = async (e) => {
    e.preventDefault();

    if (!selectedSwitch) {
      toast.error("개폐기를 선택해주세요.");
      return;
    }

    try {
      const res = await axios.post("/accessEntryUser/postLog", {
        switchId: selectedSwitch._id,
      });

      setSelectedSwitch(res.data.entrySwitch);

      res.data.entrySwitch.status
        ? toast.info("작업시작")
        : toast.success("작업종료");
    } catch (error) {
      toast.error("작업 시작에 실패했습니다.");
    }
  };

  const handleSwitchSelect = (e) => {
    const selectedValue = e.target.value;
    const selectedSwitch = entrySwitch.find((sw) => sw._id === selectedValue);
    setSelectedSwitch(selectedSwitch);
  };

  if (isLoading) return <Loading />;
  if (isError) return <span>에러가 발생했습니다.</span>;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.navBar}>
          <a href="/app-profile">
            <img src={CompanyImg} alt="" />
          </a>
          <button className={styles.hamburger}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className={styles.swtichTitle}>
          <select name="" id="" onChange={handleSwitchSelect}>
            <option className={styles.optionItem} value="">
              개폐기를 선택해주세요
            </option>
            {entrySwitch?.map((data, index) => {
              return (
                <option
                  key={index}
                  className={styles.optionItem}
                  value={data._id}
                >
                  {data.switchNumber}
                  {data?.status === true ? " 열림" : " 닫힘"}
                </option>
              );
            })}
          </select>
        </div>
        {selectedSwitch && (
          <div className={styles.main}>
            <span className={styles.switchStatus}>
              {selectedSwitch.status === true ? "열림" : "닫힘"}
            </span>
            <div className={styles.switchImg}>
              <img
                src={
                  selectedSwitch?.status === true ? OpenDoorImg : CloseDoorImg
                }
                alt=""
              />
            </div>
            <button onClick={handleWorkStart}>
              {selectedSwitch?.status === true ? "작업종료" : "작업시작"}
            </button>
          </div>
        )}

        <div className={styles.workLog}>
          <ul>
            {selectedSwitch?.logs?.slice(0, page).map((log, index) => {
              return (
                <li key={index}>
                  <div>
                    <span>
                      <b>{index + 1} </b>
                    </span>
                    <span>{log.status === true ? "열림" : "닫힘"}</span>
                  </div>
                  <span>{log.createdAt}</span>
                </li>
              );
            })}
          </ul>
          <button onClick={handleMoreData}>내역 더 보기 &gt;</button>
        </div>
      </div>
    </div>
  );
};

export default AppEntryUserMain;
