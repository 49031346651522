// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomNavLink_active__XyNeL {
  display: inline-block;
  border-bottom: 2px solid #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/module/CustomNavLink/CustomNavLink.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,6BAAA;AACF","sourcesContent":[".active {\r\n  display: inline-block;\r\n  border-bottom: 2px solid #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `CustomNavLink_active__XyNeL`
};
export default ___CSS_LOADER_EXPORT___;
