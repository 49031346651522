// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}

body {
  height: 100%;
  min-height: 100vh;
  font-family: "Roboto", "Noto Sans KR", sans-serif;
}

a {
  text-decoration: none;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/index.scss"],"names":[],"mappings":"AAMA;EACE,UAAA;EACA,SAAA;EACA,sBAAA;EACA,gBAAA;AAHF;;AAMA;EACE,YAAA;EACA,iBAAA;EACA,iDAAA;AAHF;;AAKA;EACE,qBAAA;EACA,YAAA;AAFF","sourcesContent":["//영어\r\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap\");\r\n\r\n//한글\r\n@import url(\"https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap\");\r\n\r\n* {\r\n  padding: 0;\r\n  margin: 0;\r\n  box-sizing: border-box;\r\n  list-style: none;\r\n}\r\n\r\nbody {\r\n  height: 100%;\r\n  min-height: 100vh;\r\n  font-family: \"Roboto\", \"Noto Sans KR\", sans-serif;\r\n}\r\na {\r\n  text-decoration: none;\r\n  color: black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
