import React from "react";
import styles from "./EntryListModal.module.scss";
import { useAccessEntryUseryData } from "../../../hooks/useAccessEntryUseryData/useAccessEntryUseryData";

const EntryListModal = ({ onClose, data }) => {
  const {
    data: accessEntryUseryData,
    isLoading,
    isError,
  } = useAccessEntryUseryData();

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  const filteredAccessEntryUseryDatas = accessEntryUseryData?.filter(
    (AccessEntryUseryDataItem) =>
      data.accessEntryUserId.includes(AccessEntryUseryDataItem._id)
  );

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <table>
          <thead>
            <tr>
              <th>순번</th>
              <th>개폐기번호</th>
              <th>장소</th>
              <th>권한 시작일자</th>
              <th>권한 종료일자</th>
              <th>유저 아이디</th>
              <th className={styles.registrationDate}>등록날짜</th>
            </tr>
          </thead>
          <tbody>
            {filteredAccessEntryUseryDatas.map((item, index) => (
              <tr key={`${item.userId}-${index}`}>
                <td>{index + 1}</td>
                <td>{item.switchNumber}</td>
                <td>{item.location}</td>
                <td>{item.accessStartDate}</td>
                <td>{item.accessEndDate}</td>
                <td>{item.userId}</td>
                <td>{item.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.exitButton}>
          <button type="button" onClick={onClose}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default EntryListModal;
