// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapMonitoring_half__qgEHy {
  display: flex;
  width: 100%;
  height: 100%;
}

.MapMonitoring_half__qgEHy > div {
  flex: 1 1;
  max-width: 50%;
  padding: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/MapMonitoring/MapMonitoring.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,SAAA;EACA,cAAA;EACA,eAAA;AACF","sourcesContent":[".half {\r\n  display: flex;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.half > div {\r\n  flex: 1;\r\n  max-width: 50%;\r\n  padding: 0.5rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"half": `MapMonitoring_half__qgEHy`
};
export default ___CSS_LOADER_EXPORT___;
