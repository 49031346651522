import React, { useState } from "react";
import axios from "axios";
import styles from "./Login.module.scss";
import CompanyImg from "../../assets/images/company.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();

    if (!userId || !password) {
      toast.error("아이디와 비밀번호를 확인해주세요.");
      return;
    }

    axios({
      method: "post",
      url: "/auth/login",
      data: { userId, password },
      withCredentials: true,
    })
      .then((res) => {
        dispatch(loginStart());
        if (res.data.success) {
          localStorage.clear();
          localStorage.setItem("token", res.data.token);
          dispatch(loginSuccess(res.data));
          navigate("/switch-management");
          toast.success("로그인에 성공했습니다.");
        } else {
          toast.error("아이디와 비밀번호를 확인해주세요.");
        }
      })
      .catch((err) => {
        dispatch(loginFailure());
        toast.error("아이디와 비밀번호를 확인해주세요.");
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={CompanyImg} alt="" />
        <div className={styles.main}>
          <form onSubmit={handleLogin}>
            <div className="inputWrapper">
              <div className="inputData">
                <label htmlFor="id">아이디</label>
                <input
                  type="text"
                  id="id"
                  placeholder="아이디"
                  required
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
              <div className="inputData">
                <label htmlFor="password">비밀번호</label>
                <input
                  type="password"
                  id="password"
                  autoComplete="off"
                  placeholder="비밀번호"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <button type="submit">로그인</button>
          </form>
          <p className={styles.copyright}>
            <a href="/testing">Copyrights</a> &copy; 2023{" "}
            <a href="/signup">오토이노텍</a>,
            <a href="/app-login">All rights reserved.</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
