import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import styles from "./AddSwitchModal.module.scss";

const AddSwitchModal = ({ onClose, data }) => {
  const [formData, setFormData] = useState({
    switchNumber: data?.switchNumber || "",
    location: data?.location || "",
    bluetoothId: data?.bluetoothId || "",
    authKey: data?.authKey || "",
    light: data?.light || { status: false, data: "0" },
    fan: data?.fan || { status: false, data: "0" },
    thermometer: data?.thermometer || { status: false, data: "0" },
    hygrometer: data?.hygrometer || { status: false, data: "0" },
    electricity: data?.electricity || { status: false, data: "0" },
    address: data?.address || "",
    position: data?.position || { longitude: "", latitude: "" },
  });

  const handleInputChange = (event, type) => {
    const { name, value } = event.target;
    if (type === "boolean") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: { ...prevFormData[name], status: value === "true" },
      }));
    } else if (type === "data") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: { ...prevFormData[name], data: value },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        position: {
          ...prevFormData.position,
          ...((name === "longitude" || name === "latitude") && {
            [name]: value,
          }),
        },
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("/switch", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const addedSwitch = await response.json();
        onClose(addedSwitch);
        toast.success("개폐기가 성공적으로 추가되었습니다!");
      } else {
        throw new Error(`네트워크 오류: ${response.status}`);
      }
    } catch (error) {
      toast.error("개폐기를 추가하는데 실패했습니다.");
    }
  };

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        light: data.light || { status: false, data: "0" },
        fan: data.fan || { status: false, data: "0" },
        thermometer: data.thermometer || { status: false, data: "0" },
        hygrometer: data.hygrometer || { status: false, data: "0" },
        electricity: data.electricity || { status: false, data: "0" },
        position: data.position || { longitude: "", latitude: "" },
      });
    }
  }, [data]);

  return (
    <div className={styles.modal}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formDatas}>
          <label htmlFor="switchNumber">개폐기 번호:</label>
          <input
            id="switchNumber"
            type="text"
            name="switchNumber"
            value={formData.switchNumber || ""}
            onChange={(e) => handleInputChange(e)}
            required
          />
          <label htmlFor="location">장소:</label>
          <input
            id="location"
            type="text"
            name="location"
            value={formData.location || ""}
            onChange={(e) => handleInputChange(e)}
            required
          />
          <label htmlFor="bluetoothId">블루투스 ID:</label>
          <input
            id="bluetoothId"
            type="text"
            name="bluetoothId"
            value={formData.bluetoothId || ""}
            onChange={(e) => handleInputChange(e)}
            required
          />
          <label htmlFor="authKey">인증키:</label>
          <input
            id="authKey"
            type="text"
            name="authKey"
            value={formData.authKey || ""}
            onChange={(e) => handleInputChange(e)}
            required
          />
          <label htmlFor="address">주소:</label>
          <input
            id="address"
            type="text"
            name="address"
            value={formData.address || ""}
            onChange={(e) => handleInputChange(e)}
            required
          />

          <label htmlFor="latitude">위치 (위도):</label>
          <input
            id="latitude"
            type="text"
            name="latitude"
            placeholder="위도 범위 33~43 내에서 입력"
            value={formData.position.latitude || ""}
            onChange={(e) => handleInputChange(e)}
            required
          />
          <label htmlFor="longitude">위치 (경도):</label>
          <input
            id="longitude"
            type="text"
            name="longitude"
            placeholder="경도 범위 124~132 내에서 입력"
            value={formData.position.longitude || ""}
            onChange={(e) => handleInputChange(e)}
            required
          />

          <label htmlFor="light">조명 유무 :</label>
          <div className={styles.radioBox}>
            <input
              id="light-yes"
              type="radio"
              name="light"
              value="true"
              checked={formData.light.status === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="light-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="light-no"
              type="radio"
              name="light"
              value="false"
              checked={formData.light.status === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="light-no" className={styles.customRadio}>
              무
            </label>
            <input
              type="text"
              name="light"
              value={formData.light.data}
              onChange={(e) => handleInputChange(e, "data")}
            />
          </div>

          <label htmlFor="fan">송풍기 유무:</label>
          <div className={styles.radioBox}>
            <input
              id="fan-yes"
              type="radio"
              name="fan"
              value="true"
              checked={formData.fan.status === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="fan-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="fan-no"
              type="radio"
              name="fan"
              value="false"
              checked={formData.fan.status === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="fan-no" className={styles.customRadio}>
              무
            </label>
            <input
              type="text"
              name="fan"
              value={formData.fan.data}
              onChange={(e) => handleInputChange(e, "data")}
            />
          </div>

          <label htmlFor="thermometer">온도계 유무:</label>
          <div className={styles.radioBox}>
            <input
              id="thermometer-yes"
              type="radio"
              name="thermometer"
              value="true"
              checked={formData.thermometer.status === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="thermometer-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="thermometer-no"
              type="radio"
              name="thermometer"
              value="false"
              checked={formData.thermometer.status === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="thermometer-no" className={styles.customRadio}>
              무
            </label>
            <input
              type="text"
              name="thermometer"
              value={formData.thermometer.data}
              onChange={(e) => handleInputChange(e, "data")}
            />
          </div>

          <label htmlFor="hygrometer">습도계 유무:</label>
          <div className={styles.radioBox}>
            <input
              id="hygrometer-yes"
              type="radio"
              name="hygrometer"
              value="true"
              checked={formData.hygrometer.status === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="hygrometer-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="hygrometer-no"
              type="radio"
              name="hygrometer"
              value="false"
              checked={formData.hygrometer.status === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="hygrometer-no" className={styles.customRadio}>
              무
            </label>
            <input
              type="text"
              name="hygrometer"
              value={formData.hygrometer.data}
              onChange={(e) => handleInputChange(e, "data")}
            />
          </div>

          <label htmlFor="electricity">전기 계량기 유무:</label>
          <div className={styles.radioBox}>
            <input
              id="electricity-yes"
              type="radio"
              name="electricity"
              value="true"
              checked={formData.electricity.status === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="electricity-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="electricity-no"
              type="radio"
              name="electricity"
              value="false"
              checked={formData.electricity.status === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="electricity-no" className={styles.customRadio}>
              무
            </label>
            <input
              type="text"
              name="electricity"
              value={formData.electricity.data}
              onChange={(e) => handleInputChange(e, "data")}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <button type="submit">확인</button>
          <button type="button" onClick={onClose}>
            닫기
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSwitchModal;
