import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const ExcelExportButton = ({ data, fileName, children }) => {
  const handleExport = () => {
    const headerMap = {
      switchNumber: "개폐기 번호",
      location: "장소",
      bluetoothId: "블루투스 ID",
      authKey: "인증키",
      light: "조명 유무",
      fan: "송풍기 유무",
      thermometer: "온도계 유무",
      hygrometer: "습도계 유무",
      electricity: "전력 계량기 유무",
      company: "업체",
      employeeNumber: "사원번호",
      name: "사원명",
      userId: "아이디",
      phoneNumber: "핸드폰번호",
    };

    const mappedData = data.map((row, index) => {
      const mappedRow = { 번호: index + 1 };

      if (
        row.position?.longitude !== undefined ||
        row.position?.latitude !== undefined
      ) {
        mappedRow["경도"] = row.position.longitude;
        mappedRow["위도"] = row.position.latitude;
      }

      Object.keys(headerMap).forEach((headerKey) => {
        const header = headerMap[headerKey];
        const value = row[headerKey];

        if (value !== undefined) {
          if (typeof value === "boolean") {
            mappedRow[header] = value ? "유" : "무";
          } else {
            mappedRow[header] = Array.isArray(value) ? value.join(", ") : value;
          }
        }
      });

      if (row.createdAt !== undefined) {
        mappedRow["등록일"] = row.createdAt;
      }

      return mappedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(mappedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const file = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(file, `${fileName}.xlsx`);
  };

  return <button onClick={handleExport}>{children}</button>;
};

export default ExcelExportButton;
