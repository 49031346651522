import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import styles from "./AddEntryUserModal.module.scss";
import axios from "axios";
import useCreateVerify from "../../../hooks/useCreateVerify/useCreateVerify";
import { useQueryClient } from "react-query";

const AddEntryUserModal = ({ onClose, data }) => {
  const { errors, createVerifyField } = useCreateVerify();
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    company: data?.company || "",
    employeeNumber: data?.employeeNumber || "",
    name: data?.name || "",
    userId: data?.userId || "",
    password: data?.password || "",
    phoneNumber: data?.phoneNumber || false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    // 유효성 및 중복 검사를 위한 필드 목록
    const fieldsToValidate = [
      "userId",
      "password",
      "employeeNumber",
      "phoneNumber",
    ];

    try {
      // 모든 필드에 대해 유효성 및 중복 검사 수행
      for (const fieldName of fieldsToValidate) {
        const isValid = await createVerifyField(
          fieldName,
          formData[fieldName],
          {
            validate: true,
            duplicate: true,
          }
        );
        // 검사 실패 시, 메시지를 표시하고 함수 종료

        if (!isValid) {
          toast.error(`출입자 추가 정보 형식에 맞지않습니다.`);
          return; // 여기서 함수 종료
        }
      }

      // 모든 검사를 통과했다면, 서버에 데이터 제출
      const res = await axios.post("/entry/addEntry", formData);

      if (res.status === 201) {
        queryClient.invalidateQueries("companys");
        onClose(res.data);
        toast.success("출입자를 성공적으로 추가했습니다!");
      } else {
        throw new Error(`네트워크 오류: ${res.status}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("출입자 추가를 실패했습니다.");
    }
  };

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
      });
    }
  }, [data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    let options = {
      validate: true,
      duplicate: true,
    };

    if (name === "employeeNumber") {
      options = {
        ...options,
        companyName: formData.company,
      };
    }

    createVerifyField(name, value, options);
  };

  return (
    <div className={styles.modal}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formDatas}>
          <div className={styles.form_list_first}>
            <div
              className={`${styles.form_item} ${
                errors.userId ? styles.error : ""
              }`}
            >
              <input
                className={styles.userId}
                name="userId"
                type="text"
                value={formData.userId || ""}
                onChange={handleInputChange}
                placeholder="아이디"
                onBlur={handleBlur}
                required
              />
            </div>
            <div
              className={`${styles.form_item} ${
                errors.password ? styles.error : ""
              }`}
            >
              <input
                className={styles.password}
                name="password"
                type="password"
                autoComplete="off"
                value={formData.password || ""}
                onChange={handleInputChange}
                placeholder="비밀번호"
                onBlur={handleBlur}
                required
              />
            </div>
          </div>
          {errors.userId && (
            <div className={styles.error_text}>{errors.userId}</div>
          )}
          {errors.password && (
            <div className={styles.error_text}>{errors.password}</div>
          )}

          <div className={styles.form_list_second}>
            <div className={styles.form_item}>
              <input
                className={styles.name}
                name="name"
                type="text"
                value={formData.name || ""}
                onChange={handleInputChange}
                placeholder="이름"
                required
              />
            </div>
            <div className={styles.form_item}>
              <input
                name="company"
                type="text"
                value={formData.company || ""}
                onChange={handleInputChange}
                placeholder="회사명"
                onBlur={handleBlur}
                required
              />
            </div>
            <div
              className={`${styles.form_item} ${
                errors.employeeNumber ? styles.error : ""
              }`}
            >
              <input
                className={styles.employeeNumber}
                name="employeeNumber"
                type="text"
                value={formData.employeeNumber || ""}
                onChange={handleInputChange}
                placeholder="사원번호 (회사명을 먼저 입력해주세요.)"
                onBlur={handleBlur}
                disabled={!formData.company}
                required
              />
            </div>
            <div
              className={`${styles.form_item} ${
                errors.phoneNumber ? styles.error : ""
              }`}
            >
              <input
                className={styles.phoneNumber}
                name="phoneNumber"
                type="number"
                value={formData.phoneNumber || ""}
                onChange={handleInputChange}
                placeholder="휴대전화번호 (숫자만)"
                onBlur={handleBlur}
                required
              />
            </div>
          </div>
          {errors.employeeNumber && (
            <div className={styles.error_text}>{errors.employeeNumber}</div>
          )}
          {errors.phoneNumber && (
            <div className={styles.error_text}>{errors.phoneNumber}</div>
          )}
        </div>

        <div className={styles.buttons}>
          <button type="submit">확인</button>
          <button type="button" onClick={onClose}>
            닫기
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddEntryUserModal;
