import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import axios from "axios";
import Loading from "../Loading/Loading";

const PrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifySession = async () => {
      try {
        const response = await axios.get("/auth/authSession", {
          withCredentials: true,
        });

        if (response.data.isValid) {
          setIsAuthenticated(true);
        } else {
          handleNotAuthenticated();
        }
      } catch (error) {
        handleNotAuthenticated();
      } finally {
        setIsLoading(false);
      }
    };

    verifySession();
  }, []);

  const handleNotAuthenticated = () => {
    setIsAuthenticated(false);
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
