import React, { useCallback, useMemo, useState } from "react";
import Title from "../../components/Title/Title";
import Search from "../../components/Search/Search";
import Table from "../../components/Table/Table";
import ExcelExportButton from "../../components/Table/module/ExcelExportButton/ExcelExportButton";
import axios from "axios";
import { useQuery } from "react-query";

// 데이터 fetch 함수
const fetchEntrySwitchLogsData = async () => {
  const { data } = await axios.get("/accessEntryUser/getLogs");
  return data;
};

const EntryHistory = () => {
  const [filteredData, setFilteredData] = useState([]);

  const searchShowSettings = useMemo(
    () => ({
      switchNumber: true,
      location: true,
      name: true,
      userId: true,
      phoneNumber: true,
      company: true,
      registrationDate: true,
    }),
    []
  );

  const tableShowSettings = useMemo(
    () => ({
      number: true,
      switchNumber: true,
      location: true,
      company: true,
      employeeNumber: true,
      name: true,
      userId: true,
      phoneNumber: true,
      registrationDate: true,
      status: true,
    }),
    []
  );

  // React Query를 사용한 데이터 fetch
  const { data: entrySwitchLogs, isLoading } = useQuery(
    "entrySwitchLogs",
    fetchEntrySwitchLogsData
  );

  // 검색 결과 핸들러
  const handleSearchResults = useCallback((results) => {
    if (results.length === 0) {
      alert("검색 결과가 없습니다.");
    }
    setFilteredData(results);
  }, []);

  return (
    <div>
      <Title
        title="출입 내역조회"
        button1={
          <ExcelExportButton
            data={filteredData.length > 0 ? filteredData : entrySwitchLogs}
            fileName="출입내역"
          >
            출입내역 엑셀출력
          </ExcelExportButton>
        }
      />
      <Search
        show={searchShowSettings}
        data={entrySwitchLogs}
        onSearchResults={handleSearchResults}
      />
      <Table
        show={tableShowSettings}
        data={filteredData.length > 0 ? filteredData : entrySwitchLogs}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EntryHistory;
