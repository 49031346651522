import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styles from "./Table.module.scss";
import headers from "./data/headers";
import UpdateSwitchModal from "../Modal/UpdateSwitchModal/UpdateSwitchModal";
import SwitchListModal from "../Modal/SwitchListModal/SwitchListModal";
import EntryListModal from "../Modal/EntryListModal/EntryListModal";
import AddSwitchAccessModal from "../Modal/AddSwitchAccessModal/AddSwitchAccessModal";
import UpdateEntryUserModal from "../Modal/UpdateEntryUserModal/UpdateEntryUserModal";
import Status from "./module/Status/Status";
import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineVerticalLeft,
  AiOutlineVerticalRight,
} from "react-icons/ai";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";

import {
  setOpenUpdateSwitchModal,
  setOpenUpdateEntryUserModal,
  setOpenSwitchListModal,
  setOpenEntryListModal,
  setOpenAddSwitchAccessModal,
  setCurrentRow,
  setOpenNaverMapModal,
} from "../../redux/modalSlice";
import NaverMapModal from "../Modal/NaverMapModal/NaverMapModal";

const Table = ({ show, data, isLoading, onDelete }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);
  const [datas, setdatas] = useState([]);
  const location = useLocation();

  const dispatch = useDispatch();
  const {
    openUpdateSwitchModal,
    openUpdateEntryUserModal,
    openSwitchListModal,
    openEntryListModal,
    openAddSwitchAccessModal,
    openNaverMapModal,
    currentRow,
  } = useSelector((state) => state.modals);

  useEffect(() => {
    if (data && data.length > 0) {
      const end = currentPage * perPage;
      const start = end - perPage;
      setdatas(data.slice(start, end));
    }
  }, [currentPage, perPage, data]);

  useEffect(() => {
    if (datas.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [datas, currentPage]);

  const handleOpenSwitchListModal = (row) => {
    dispatch(setCurrentRow(row));
    dispatch(setOpenSwitchListModal(true));
  };

  const handleOpenEntryListModal = (row) => {
    dispatch(setCurrentRow(row));
    dispatch(setOpenEntryListModal(true));
  };

  const handleOpenAddSwitchAccessModal = (row) => {
    dispatch(setCurrentRow(row));
    dispatch(setOpenAddSwitchAccessModal(true));
  };

  const handleOpenNaverMapModal = (row) => {
    dispatch(setCurrentRow(row));
    dispatch(setOpenNaverMapModal(true));
  };

  const handleUpdateRow = (updatedRow) => {
    setdatas((prevData) =>
      prevData.map((row) => (row._id === updatedRow._id ? updatedRow : row))
    );
  };

  const handleUrlLocationButton = (row) => {
    const urlLocation = location.pathname.split("-")[0];
    if (urlLocation === "/switch") {
      dispatch(setCurrentRow(row));
      dispatch(setOpenUpdateSwitchModal(true));
    } else if (urlLocation === "/entry") {
      dispatch(setCurrentRow(row));
      dispatch(setOpenUpdateEntryUserModal(true));
    }
  };

  const handleDelete = async (row) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        if (row._id) {
          onDelete(row._id);
          setdatas((prevData) =>
            prevData.filter((item) => item._id !== row._id)
          );
          toast.success("성공적으로 삭제했습니다.");
        }
      } catch (error) {
        console.error(error);
        toast.error("삭제에 실패했습니다. 다시 시도해주세요.");
      }
    }
  };

  const totalPages =
    data && data.length > 0 ? Math.ceil(data.length / perPage) : 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              {headers.map((header) =>
                show[header.id] ? <th key={header.id}>{header.name}</th> : null
              )}
            </tr>
          </thead>
          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={headers.length}>
                  <Loading />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {Array.isArray(datas) && datas.length > 0 ? (
                datas.map((data, index) => {
                  const itemNumber = index + 1 + (currentPage - 1) * perPage;
                  return (
                    <tr key={index}>
                      {headers.map((header) => {
                        if (!show[header.id]) return null;
                        switch (header.id) {
                          case "number":
                            return <td key={header.id}>{itemNumber}</td>;
                          case "accessPermissionHistory":
                            return (
                              <td key={header.id}>
                                <button
                                  onClick={() =>
                                    handleOpenSwitchListModal(data)
                                  }
                                >
                                  내역
                                </button>
                                <button
                                  onClick={() =>
                                    handleOpenAddSwitchAccessModal(data)
                                  }
                                >
                                  권한 추가
                                </button>
                              </td>
                            );
                          case "editDelete":
                            return (
                              <td key={header.id}>
                                <button
                                  onClick={() => handleUrlLocationButton(data)}
                                >
                                  수정
                                </button>
                                <button onClick={() => handleDelete(data)}>
                                  삭제
                                </button>
                              </td>
                            );
                          case "viewLocation":
                            return (
                              <td key={header.id}>
                                <button
                                  onClick={() => {
                                    handleOpenNaverMapModal(data);
                                  }}
                                >
                                  지도
                                </button>
                              </td>
                            );
                          case "switchPermission":
                            return (
                              <td key={header.id}>
                                <button
                                  onClick={() => handleOpenEntryListModal(data)}
                                >
                                  보기
                                </button>
                              </td>
                            );
                          case "status":
                            return (
                              <td key={header.id}>
                                <Status status={data.status} />
                              </td>
                            );
                          case "switchNumber":
                            return <td key={header.id}>{data.switchNumber}</td>;
                          case "location":
                            return (
                              <td key={header.id}>
                                <div
                                  className={styles.locationBtn}
                                  onClick={() => handleOpenNaverMapModal(data)}
                                >
                                  {data.location}
                                </div>
                              </td>
                            );
                          case "light":
                            return (
                              <td key={header.id}>
                                {data.light.status
                                  ? `유 ${data.light.data}`
                                  : "무 "}
                              </td>
                            );
                          case "fan":
                            return (
                              <td key={header.id}>
                                {data.fan.status
                                  ? `유 ${data.fan.data}`
                                  : "무 "}
                              </td>
                            );
                          case "thermometer":
                            return (
                              <td key={header.id}>
                                {data.thermometer.status
                                  ? `유 ${data.thermometer.data}`
                                  : "무 "}
                              </td>
                            );
                          case "hygrometer":
                            return (
                              <td key={header.id}>
                                {data.hygrometer.status
                                  ? `유 ${data.hygrometer.data}`
                                  : "무 "}
                              </td>
                            );
                          case "powerMeter":
                            return (
                              <td key={header.id}>
                                {data.electricity.status
                                  ? `유 ${data.electricity.data}`
                                  : "무 "}
                              </td>
                            );
                          case "company":
                            return <td key={header.id}>{data.company}</td>;
                          case "employeeNumber":
                            return (
                              <td key={header.id}>{data.employeeNumber}</td>
                            );
                          case "name":
                            return <td key={header.id}>{data.name}</td>;
                          case "userId":
                            return <td key={header.id}>{data.userId}</td>;
                          case "phoneNumber":
                            return <td key={header.id}>{data.phoneNumber}</td>;
                          case "accessStartDate":
                            return (
                              <td key={header.id}>{data.accessStartDate}</td>
                            );
                          case "accessEndDate":
                            return (
                              <td key={header.id}>{data.accessEndDate}</td>
                            );
                          case "changeTime":
                            return (
                              <td key={header.id}>
                                {data?.logs[0]?.createdAt
                                  ? data.logs[0].createdAt
                                  : "출입 내역이 없습니다."}
                              </td>
                            );
                          case "registrationDate":
                            return <td key={header.id}>{data.createdAt}</td>;
                          default:
                            return <td key={header.id}>-</td>;
                        }
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headers.length}>
                    데이터 추가 또는 검색을 해주세요.
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>

      <div className={styles.pagination}>
        {currentPage > 1 && (
          <button onClick={() => setCurrentPage(1)} className={styles.prev}>
            <AiOutlineVerticalRight />
          </button>
        )}
        {currentPage > 5 && (
          <button
            onClick={() => setCurrentPage(currentPage - 5)}
            className={styles.prev}
          >
            <AiOutlineDoubleLeft />
          </button>
        )}
        {Array.from(
          { length: 5 },
          (_, i) => i + 1 + Math.floor((currentPage - 1) / 5) * 5
        ).map(
          (page) =>
            page <= totalPages && (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={page === currentPage ? styles.active : ""}
              >
                {page}
              </button>
            )
        )}
        {currentPage < totalPages && (
          <button
            onClick={() =>
              setCurrentPage(Math.min(currentPage + 5, totalPages))
            }
            className={styles.next}
          >
            <AiOutlineDoubleRight />
          </button>
        )}
        {currentPage < totalPages && (
          <button
            onClick={() => setCurrentPage(totalPages)}
            className={styles.next}
          >
            <AiOutlineVerticalLeft />
          </button>
        )}
      </div>

      {openUpdateSwitchModal && (
        <UpdateSwitchModal
          onClose={() => {
            dispatch(setOpenUpdateSwitchModal(false));
          }}
          onUpdate={handleUpdateRow}
          data={currentRow}
        />
      )}

      {openUpdateEntryUserModal && (
        <UpdateEntryUserModal
          onClose={() => {
            dispatch(setOpenUpdateEntryUserModal(false));
          }}
          onUpdate={handleUpdateRow}
          data={currentRow}
        />
      )}
      {openSwitchListModal && (
        <SwitchListModal
          onClose={() => {
            dispatch(setOpenSwitchListModal(false));
          }}
          data={currentRow}
        />
      )}
      {openEntryListModal && (
        <EntryListModal
          onClose={() => {
            dispatch(setOpenEntryListModal(false));
          }}
          data={currentRow}
        />
      )}
      {openAddSwitchAccessModal && (
        <AddSwitchAccessModal
          onClose={() => {
            dispatch(setOpenAddSwitchAccessModal(false));
          }}
          onUpdate={handleUpdateRow}
          data={currentRow}
        />
      )}

      {openNaverMapModal && (
        <NaverMapModal
          onClose={() => {
            console.log();

            dispatch(setOpenNaverMapModal(false));
          }}
          data={currentRow}
        />
      )}
    </div>
  );
};

export default Table;
