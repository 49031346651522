// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loadingOverlay__lK2za {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.Loading_loadingOverlay__lK2za .Loading_loadingSpinner__qofnM {
  color: #000;
  font-size: 2rem;
  font-weight: 800;
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".loadingOverlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background-color: rgba(0, 0, 0, 0.1);\r\n  z-index: 9999;\r\n\r\n  .loadingSpinner {\r\n    color: #000;\r\n    font-size: 2rem;\r\n    font-weight: 800;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingOverlay": `Loading_loadingOverlay__lK2za`,
	"loadingSpinner": `Loading_loadingSpinner__qofnM`
};
export default ___CSS_LOADER_EXPORT___;
