import React, { useEffect, useMemo, useRef } from "react";
import styles from "./NaverMap.module.scss";

const NaverMap = ({ data }) => {
  const mapRef = useRef(null);
  const { naver } = window;

  const LatLngClass = naver.maps.LatLng;

  const arr = useMemo(() => data?.map((item) => item.position) || [], [data]);

  const location = useMemo(
    () => new LatLngClass(37.5666103, 126.9783882),
    [LatLngClass]
  );
  const mapOptions = useMemo(
    () => ({
      center: location,
      logoControl: false,
      mapDataControl: false,
      scaleControl: true,
      tileDuration: 200,
      zoom: 14,
      zoomControl: true,
      zoomControlOptions: { position: 9 },
    }),
    [location]
  );

  const contentString = useMemo(
    () =>
      [
        '<div class="iw_inner">',
        "   <h3>서울특별시청</h3>",
        "   <p>서울특별시 중구 태평로1가 31 | 서울특별시 중구 세종대로 110 서울특별시청<br />",
        "       02-120 | 공공,사회기관 &gt; 특별,광역시청<br />",
        '       <a href="http://www.seoul.go.kr" target="_blank">www.seoul.go.kr/</a>',
        "   </p>",
        "</div>",
      ].join(""),
    []
  );

  useEffect(() => {
    const map = new naver.maps.Map(mapRef.current, mapOptions);

    arr.forEach((position) => {
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(position.longitude, position.latitude),
        map,
      });

      const infowindow = new naver.maps.InfoWindow({
        content: contentString,
      });

      naver.maps.Event.addListener(marker, "click", function () {
        if (infowindow.getMap()) {
          infowindow.close();
        } else {
          infowindow.open(map, marker);
        }
      });
    });
  }, [arr, mapOptions, naver, contentString]);

  return (
    <div className={styles.mapWapper}>
      <div id={styles.map} ref={mapRef} />
    </div>
  );
};

export default NaverMap;
