import React, { useState, useMemo, useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Title from "../../components/Title/Title";
import Search from "../../components/Search/Search";
import Table from "../../components/Table/Table";
import ExcelExportButton from "../../components/Table/module/ExcelExportButton/ExcelExportButton";
import AddSwitchModal from "../../components/Modal/AddSwitchModal/AddSwitchModal";
import Loading from "../../components/Loading/Loading";

const fetchSwitchs = async () => {
  const { data } = await axios.get("/switch");
  return data;
};

const handleDelete = async (switchId) => {
  await axios.delete(`/switch/delete`, {
    data: { id: switchId },
  });
};

const SwitchManagement = () => {
  const queryClient = useQueryClient();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const SearchShowSettings = useMemo(
    () => ({
      switchNumber: true,
      location: true,
      employeeName: true,
      registrationDate: true,
    }),
    []
  );

  const TableShowSettings = useMemo(
    () => ({
      number: true,
      switchNumber: true,
      location: true,
      light: true,
      fan: true,
      thermometer: true,
      hygrometer: true,
      powerMeter: true,
      registrationDate: true,
      editDelete: true,
      accessPermissionHistory: true,
      viewLocation: true,
    }),
    []
  );

  const {
    data: switchs,
    isLoading,
    isError,
  } = useQuery("switchs", fetchSwitchs, {});

  const handleSearchResults = useCallback((results) => {
    if (results.length === 0) {
      alert("검색 결과가 없습니다.");
    }
    setFilteredData(results);
  }, []);

  const handleOpenAddSwitchModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddSwitchUserModal = (addedSwitch) => {
    setIsAddModalOpen(false);
    if (addedSwitch && addedSwitch.switchNumber) {
      queryClient.setQueryData("switchs", (oldSwitchs) => [
        ...oldSwitchs,
        addedSwitch,
      ]);
    }
  };

  if (isLoading) return <Loading />;
  if (isError) return <span>에러가 발생했습니다.</span>;

  return (
    <div>
      <Title
        title="개폐기 관리"
        button1={
          <ExcelExportButton
            data={filteredData.length > 0 ? filteredData : switchs}
            fileName="개폐기 정보"
          >
            개폐기 정보 엑셀출력
          </ExcelExportButton>
        }
        button2={{
          text: "개폐기 추가하기",
          onClick: handleOpenAddSwitchModal,
        }}
      />

      <Search
        show={SearchShowSettings}
        data={switchs}
        onSearchResults={handleSearchResults}
      />
      <Table
        show={TableShowSettings}
        isLoading={isLoading}
        data={filteredData.length > 0 ? filteredData : switchs}
        onDelete={handleDelete}
      />

      {isAddModalOpen && (
        <AddSwitchModal
          open={isAddModalOpen}
          onClose={handleCloseAddSwitchUserModal}
        />
      )}
    </div>
  );
};

export default SwitchManagement;
