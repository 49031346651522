import { useQuery } from "react-query";
import axios from "axios";

const fetchAccessEntryUseryData = async () => {
  const response = await axios.get("/accessEntryUser");
  return response.data;
};

export const useAccessEntryUseryData = () => {
  return useQuery("accessEntryUseryData", fetchAccessEntryUseryData);
};
