import React, { useState } from "react";
import styles from "./Search.module.scss";
import { toast } from "react-toastify";
import { useCompanys } from "../../hooks/useCompanys/useCompanys";

const Search = ({ show, data, onSearchResults }) => {
  const { data: companys } = useCompanys();

  const initialSearchTerms = {
    switchNumber: "",
    location: "",
    name: "",
    userId: "",
    phoneNumber: "",
    company: "",
    startDate: "",
    endDate: "",
  };

  const [searchTerms, setSearchTerms] = useState(initialSearchTerms);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [id]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let filteredResults = data;

    if (searchTerms.switchNumber) {
      filteredResults = filteredResults.filter((log) =>
        log.switchNumber
          .toLowerCase()
          .includes(searchTerms.switchNumber.toLowerCase())
      );
    }
    if (searchTerms.location) {
      filteredResults = filteredResults.filter((log) =>
        log.location.toLowerCase().includes(searchTerms.location.toLowerCase())
      );
    }
    if (searchTerms.name) {
      filteredResults = filteredResults.filter((log) =>
        log.name.toLowerCase().includes(searchTerms.name.toLowerCase())
      );
    }
    if (searchTerms.userId) {
      filteredResults = filteredResults.filter((log) =>
        log.userId.toLowerCase().includes(searchTerms.userId.toLowerCase())
      );
    }
    if (searchTerms.phoneNumber) {
      filteredResults = filteredResults.filter((log) =>
        log.phoneNumber.includes(searchTerms.phoneNumber)
      );
    }
    if (searchTerms.company) {
      filteredResults = filteredResults.filter((log) =>
        log.company.toLowerCase().includes(searchTerms.company.toLowerCase())
      );
    }
    if (searchTerms.startDate && searchTerms.endDate) {
      filteredResults = filteredResults.filter((log) => {
        const logDate = new Date(log.createdAt);
        return (
          logDate >= new Date(searchTerms.startDate) &&
          logDate <= new Date(searchTerms.endDate)
        );
      });
    }

    if (filteredResults.length > 0) {
      onSearchResults(filteredResults);
      toast.success("데이터가 검색되었습니다.");
    } else {
      toast.info("검색 결과가 없습니다.");
    }
  };

  const handleResetButton = () => {
    setSearchTerms(initialSearchTerms);
    onSearchResults(data); // Reset the filter and show all data
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <div className={styles.dataWrapper}>
          {show.switchNumber && (
            <div>
              <label htmlFor="switchNumber">개폐기번호</label>
              <input
                type="text"
                id="switchNumber"
                value={searchTerms.switchNumber}
                onChange={handleInputChange}
                placeholder="개폐기번호"
              />
            </div>
          )}
          {show.location && (
            <div>
              <label htmlFor="location">장소</label>
              <input
                type="text"
                id="location"
                value={searchTerms.location}
                onChange={handleInputChange}
                placeholder="장소"
              />
            </div>
          )}
          {show.name && (
            <div>
              <label htmlFor="name">사원명</label>
              <input
                type="text"
                id="name"
                value={searchTerms.name}
                onChange={handleInputChange}
                placeholder="사원명"
              />
            </div>
          )}
          {show.userId && (
            <div>
              <label htmlFor="userId">아이디</label>
              <input
                type="text"
                id="userId"
                value={searchTerms.userId}
                onChange={handleInputChange}
                placeholder="아이디"
              />
            </div>
          )}
          {show.phoneNumber && (
            <div>
              <label htmlFor="phoneNumber">핸드폰번호</label>
              <input
                type="tel"
                id="phoneNumber"
                value={searchTerms.phoneNumber}
                onChange={handleInputChange}
                placeholder="핸드폰번호"
              />
            </div>
          )}
          {show.company && (
            <div>
              <label htmlFor="company">업체</label>
              <select
                id="company"
                value={searchTerms.company}
                onChange={handleInputChange}
              >
                <option value="">업체 선택</option>
                {companys?.map((company, index) => (
                  <option value={company} key={index}>
                    {company}
                  </option>
                ))}
              </select>
            </div>
          )}
          {show.registrationDate && (
            <div className={styles.selectDate}>
              <label htmlFor="startDate">등록날짜</label>
              <figure>
                <input
                  id="startDate"
                  type="date"
                  value={searchTerms.startDate}
                  onChange={handleInputChange}
                />
                <p></p>
                <input
                  id="endDate"
                  type="date"
                  value={searchTerms.endDate}
                  onChange={handleInputChange}
                  min={searchTerms.startDate}
                />
              </figure>
            </div>
          )}
          <div className={styles.resetBtn}>
            <button type="button" onClick={handleResetButton}>
              초기화
            </button>
          </div>
        </div>
        <div className={styles.submitBtn}>
          <button type="submit">검색하기</button>
        </div>
      </form>
    </div>
  );
};

export default Search;
