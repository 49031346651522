import axios from "axios";
import React from "react";

const Testing = () => {
  const apiTest = async () => {
    const { data } = await axios.get("/accessEntryUser");

    console.log(data);
  };

  return (
    <div>
      <button onClick={apiTest}>API TEST</button>
    </div>
  );
};

export default Testing;
