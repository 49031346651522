import { useMatch, Link } from "react-router-dom";
import styles from "./CustomNavLink.module.scss";

export const CustomNavLink = ({ to, children, ...props }) => {
  const match = useMatch(to);

  return (
    <Link to={to} {...props} className={match ? styles.active : ""}>
      {children}
    </Link>
  );
};
