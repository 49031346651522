import React from "react";
import styles from "./Status.module.scss";
import { useLocation } from "react-router-dom";

const Status = ({ status }) => {
  const location = useLocation();
  const isMonitoring =
    location.pathname === "/entry-monitoring" ||
    location.pathname === "/map-monitoring";

  return (
    <div className={styles.container}>
      <div className={status ? styles.open : styles.close}>
        {status ? "open" : "close"}
      </div>
      {isMonitoring && (
        <div className={status ? styles.workIn : styles.workOut}>
          {status ? "작업중" : "작업종료"}
        </div>
      )}
    </div>
  );
};

export default Status;
