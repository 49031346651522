// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NaverMap_mapWapper__-EJcZ {
  display: grid;
  place-items: center;
}
.NaverMap_mapWapper__-EJcZ #NaverMap_map__PZHZO {
  width: 98%;
  height: 295px;
}`, "",{"version":3,"sources":["webpack://./src/components/NaverMap/NaverMap.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,UAAA;EACA,aAAA;AACJ","sourcesContent":[".mapWapper {\r\n  display: grid;\r\n  place-items: center;\r\n\r\n  #map {\r\n    width: 98%;\r\n    height: 295px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapWapper": `NaverMap_mapWapper__-EJcZ`,
	"map": `NaverMap_map__PZHZO`
};
export default ___CSS_LOADER_EXPORT___;
