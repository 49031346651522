import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modals",
  initialState: {
    openUpdateSwitchModal: false,
    openUpdateEntryUserModal: false,
    openSwitchListModal: false,
    openEntryListModal: false,
    openAddSwitchAccessModal: false,
    openNaverMapModal: false,
    currentRow: null,
  },
  reducers: {
    setOpenUpdateSwitchModal: (state, action) => {
      state.openUpdateSwitchModal = action.payload;
    },
    setOpenUpdateEntryUserModal: (state, action) => {
      state.openUpdateEntryUserModal = action.payload;
    },

    setOpenSwitchListModal: (state, action) => {
      state.openSwitchListModal = action.payload;
    },
    setOpenEntryListModal: (state, action) => {
      state.openEntryListModal = action.payload;
    },

    setOpenAddSwitchAccessModal: (state, action) => {
      state.openAddSwitchAccessModal = action.payload;
    },

    setOpenNaverMapModal: (state, action) => {
      state.openNaverMapModal = action.payload;
    },

    setCurrentRow: (state, action) => {
      state.currentRow = action.payload;
    },
  },
});

export const {
  setOpenUpdateSwitchModal,
  setOpenUpdateEntryUserModal,
  setOpenSwitchListModal,
  setOpenEntryListModal,
  setOpenAddSwitchAccessModal,
  setOpenNaverMapModal,

  setCurrentRow,
} = modalSlice.actions;

export default modalSlice.reducer;
