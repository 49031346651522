import { useQuery } from "react-query";
import axios from "axios";

const fetchCompanys = async () => {
  const { data } = await axios.get("/entry/companys");
  return data;
};

export const useCompanys = () => {
  return useQuery("companys", fetchCompanys);
};
