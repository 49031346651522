import React, { useState, useMemo, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import Table from "../../components/Table/Table";
import Search from "../../components/Search/Search";
import Title from "../../components/Title/Title";
import ExcelExportButton from "../../components/Table/module/ExcelExportButton/ExcelExportButton";
import AddEntryUserModal from "../../components/Modal/AddEntryUserModal/AddEntryUserModal";
import Loading from "../../components/Loading/Loading";

const fetchEntries = async () => {
  const { data } = await axios.get("/entry/getEntry");
  return data;
};

const handleDeleteEntry = async (entryId) => {
  await axios.delete(`/entry/delete`, {
    data: { id: entryId },
  });
};

const EntryManagement = () => {
  const queryClient = useQueryClient();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const SearchShowSettings = useMemo(
    () => ({
      name: true,
      userId: true,
      phoneNumber: true,
      company: true,
      registrationDate: true,
    }),
    []
  );

  const TableShowSettings = useMemo(
    () => ({
      company: true,
      employeeNumber: true,
      name: true,
      userId: true,
      phoneNumber: true,
      registrationDate: true,
      entryPermission: true,
      switchPermission: true,
      editDelete: true,
    }),
    []
  );

  const {
    data: entries,
    isLoading,
    isError,
  } = useQuery("entries", fetchEntries, {});

  const deleteMutation = useMutation(handleDeleteEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries("entries");
    },
  });

  const handleSearchResults = useCallback((results) => {
    if (results.length === 0) {
      alert("검색 결과가 없습니다.");
    }
    setFilteredData(results);
  }, []);

  const handleDelete = (entryId) => {
    deleteMutation.mutate(entryId);
  };

  const handleOpenAddEntryUserModal = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddEntryUserModal = (addedEntry) => {
    setIsAddModalOpen(false);
    if (addedEntry && addedEntry.userId) {
      queryClient.setQueryData("entries", (oldEntries) => [
        ...oldEntries,
        addedEntry,
      ]);
    }
  };

  if (isLoading) return <Loading />;
  if (isError) return <span>에러가 발생했습니다.</span>;

  return (
    <div>
      <Title
        title={"출입자 관리"}
        button1={
          <ExcelExportButton
            data={filteredData.length > 0 ? filteredData : entries}
            fileName="출입자 정보"
          >
            출입자 정보 엑셀출력
          </ExcelExportButton>
        }
        button2={{
          text: "출입자 추가하기",
          onClick: handleOpenAddEntryUserModal,
        }}
      />
      <Search
        show={SearchShowSettings}
        data={entries}
        onSearchResults={handleSearchResults}
      />
      <Table
        show={TableShowSettings}
        data={filteredData.length > 0 ? filteredData : entries}
        onDelete={handleDelete}
      />

      {isAddModalOpen && (
        <AddEntryUserModal
          open={isAddModalOpen}
          onClose={handleCloseAddEntryUserModal}
        />
      )}
    </div>
  );
};

export default EntryManagement;
