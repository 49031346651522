import React, { useCallback, useMemo, useState } from "react";
import Title from "../../components/Title/Title";
import Search from "../../components/Search/Search";
import Table from "../../components/Table/Table";
import axios from "axios";
import { useQuery } from "react-query";

const fetchEntrySwitch = async () => {
  const { data } = await axios.get("/accessEntryUser");
  return data;
};

const EntryMonitoring = () => {
  const [filteredData, setFilteredData] = useState([]);

  const searchShowSettings = useMemo(
    () => ({
      switchNumber: true,
      location: true,
      name: true,
      userId: true,
      phoneNumber: true,
      company: true,
    }),
    []
  );

  const TableShowSettings = useMemo(
    () => ({
      number: true,
      switchNumber: true,
      location: true,
      company: true,
      employeeNumber: true,
      name: true,
      userId: true,
      phoneNumber: true,
      changeTime: true,
      status: true,
    }),
    []
  );

  const { data: entrySwitch, isLoading } = useQuery(
    "entrySwitch",
    fetchEntrySwitch
  );

  const handleSearchResults = useCallback((results) => {
    if (results.length === 0) {
      alert("검색 결과가 없습니다.");
    }
    setFilteredData(results);
  }, []);

  return (
    <div>
      <Title title={"출입 모니터링"} data={entrySwitch} />
      <Search
        show={searchShowSettings}
        data={entrySwitch}
        onSearchResults={handleSearchResults}
      />
      <Table
        show={TableShowSettings}
        data={filteredData.length > 0 ? filteredData : entrySwitch}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EntryMonitoring;
