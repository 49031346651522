import React, { useEffect } from "react";
import styles from "./Navbar.module.scss";
import { useLocation } from "react-router-dom";
import CompanyImg from "../../assets/images/company2.png";
import { CustomNavLink } from "./module/CustomNavLink/CustomNavLink";
import menuItems from "./util/menuItems";
import UserMenu from "./module/UserMenu/UserMenu";
import { useSelector } from "react-redux";

const Navbar = () => {
  const location = useLocation();
  const { currentUser } = useSelector((state) => state.user);

  const toggleMenu = () => {
    const hamburger = document.querySelector(`.${styles.hamburger}`);
    const mobileMenu = document.querySelector(`.${styles.mobileMenu}`);
    if (hamburger && mobileMenu) {
      hamburger.classList.toggle(`${styles.active}`);
      mobileMenu.classList.toggle(`${styles.show}`);
    }
  };

  const closeMenu = () => {
    const hamburger = document.querySelector(`.${styles.hamburger}`);
    const mobileMenu = document.querySelector(`.${styles.mobileMenu}`);
    if (hamburger && mobileMenu) {
      hamburger.classList.remove(`${styles.active}`);
      mobileMenu.classList.remove(`${styles.show}`);
    }
  };

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <div className={styles.container}>
      <img src={CompanyImg} alt="" />
      <div className={styles.Wrapper}>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <div></div>
        </div>
        <ul className={styles.mainBtn}>
          {menuItems.map((item) => (
            <li key={item.name}>
              <CustomNavLink
                to={item.to}
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                {item.name}
              </CustomNavLink>
            </li>
          ))}
        </ul>
        {currentUser && currentUser.data && (
          <UserMenu userName={currentUser.data.name} />
        )}
        <div className={styles.mobileMenu}>
          <ul>
            {menuItems.map((item) => (
              <li key={item.name}>
                <CustomNavLink
                  to={item.to}
                  className={({ isActive }) => (isActive ? styles.active : "")}
                >
                  {item.name}
                </CustomNavLink>
              </li>
            ))}
          </ul>
          {currentUser && currentUser.data && (
            <UserMenu userName={currentUser.data.name} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
