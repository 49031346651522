import React, { useState } from "react";
import axios from "axios";
import styles from "./AppEntryUserLogin.module.scss";
import CompanyImg from "../../assets/images/company.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";
// import { client, utils } from "@passwordless-id/webauthn";

const AppEntryUserLogin = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [credentialID, setCredentialID] = useState("");

  const handleAppEntryUserLogin = (e) => {
    e.preventDefault();

    if (!userId || !password) {
      toast.error("아이디와 비밀번호를 확인해주세요.");
      return;
    }

    axios({
      method: "post",
      url: "/entry/login",
      data: { userId, password },
      withCredentials: true,
    })
      .then((res) => {
        dispatch(loginStart());
        if (res.data.success) {
          localStorage.clear();
          localStorage.setItem("token", res.data.token);
          dispatch(loginSuccess(res.data));
          navigate("/app-main");
          toast.success("로그인에 성공했습니다.");
        } else {
          toast.error("아이디와 비밀번호를 확인해주세요.");
        }
      })
      .catch((err) => {
        dispatch(loginFailure());
        toast.error("아이디와 비밀번호를 확인해주세요.");
      });
  };

  // const handleAuthentication = async () => {
  //   try {
  //     const challenge = utils.randomChallenge();

  //     const authentication = await client.authenticate(
  //       [credentialID],
  //       challenge,
  //       {
  //         authenticatorType: "auto",
  //         userVerification: "required",
  //         timeout: 60000,
  //       }
  //     );

  //     await axios.post("/entry/authentication", {
  //       authentication,
  //       challenge,
  //     });

  //     toast.success("인증 되었습니다.");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img className={styles.companyImg} src={CompanyImg} alt="" />
        <div className={styles.main}>
          <form className={styles.form} onSubmit={handleAppEntryUserLogin}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputData}>
                <label className={styles.label} htmlFor="id">
                  아이디
                </label>
                <input
                  className={styles.input}
                  type="text"
                  id="id"
                  placeholder="아이디"
                  required
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
              <div className={styles.inputData}>
                <label className={styles.label} htmlFor="password">
                  비밀번호
                </label>
                <input
                  className={styles.input}
                  type="password"
                  id="password"
                  autoComplete="off"
                  placeholder="비밀번호"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <button className={styles.submitButton} type="submit">
              로그인
            </button>
          </form>
          {/* <button
            className={styles.submitButton}
            onClick={handleAuthentication}
          >
            지문 로그인
          </button> */}
        </div>
      </div>
      <p className={styles.version}>ver 2.1.3</p>
    </div>
  );
};

export default AppEntryUserLogin;
