import React from "react";
// import Navbar from "../Navbar/Navbar";
// import Footer from "../Footer/Footer";

const AppLayout = ({ children }) => {
  return (
    <>
      {/* <AppNavbar /> */}
      {children}
      {/* <AppFooter /> */}
    </>
  );
};
export default AppLayout;
