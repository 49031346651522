import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import styles from "./AddSwitchAccessModal.module.scss";
import { toast } from "react-toastify";

const fetchEntryData = async () => {
  const { data } = await axios.get("/entry/getEntry");
  return data;
};

const useEntryData = () => {
  return useQuery("entryData", fetchEntryData);
};

const AddSwitchAccessModal = ({ onClose, data: switchData, onUpdate }) => {
  const queryClient = useQueryClient();
  const { data: entryData, isLoading, isError } = useEntryData();

  const [formData, setFormData] = useState({
    userId: "",
    startDate: "",
    endDate: "",
    entryId: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "userId") {
      const entry = entryData.find((entry) => entry.userId === value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: value,
        entryId: entry?._id || "",
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const addAccessMutation = useMutation(
    (newAccessData) => axios.post("/entry/addAccess", newAccessData),
    {
      onSuccess: (response) => {
        onUpdate(response.data.updatedSwitch);
        queryClient.invalidateQueries("entryData");
        toast.success("성공적으로 추가 되었습니다.");
        onClose();
      },
      onError: (error) => {
        console.error("Error adding access:", error);
      },
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    addAccessMutation.mutate({
      ...formData,
      switchId: switchData._id,
    });
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching the data</div>;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formRow}>
            <label htmlFor="switchNumber">개폐기 정보:</label>
            <span>{`${switchData.switchNumber} (${switchData.location})`}</span>
          </div>
          <div className={styles.formRow}>
            <label htmlFor="userId">권한할당 대상:</label>
            <select
              id="userId"
              name="userId"
              value={formData.userId}
              onChange={handleInputChange}
              required
            >
              <option value="">선택하세요</option>
              {entryData?.map((entry) => {
                return (
                  <option key={entry._id} value={entry.userId}>
                    {entry.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className={styles.formRow}>
            <label htmlFor="startDate">권한 시작일자:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.formRow}>
            <label htmlFor="endDate">권한 종료일자:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              min={formData.startDate}
              required
            />
          </div>

          <div className={styles.buttons}>
            <button type="submit">확인</button>
            <button type="button" onClick={onClose}>
              닫기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSwitchAccessModal;
