import React, { useCallback, useMemo, useState } from "react";
import Title from "../../components/Title/Title";
import Search from "../../components/Search/Search";
import Table from "../../components/Table/Table";
import axios from "axios";
import { useQuery } from "react-query";
import NaverMap from "../../components/NaverMap/NaverMap";
import styles from "./MapMonitoring.module.scss";

const fetchEntrySwitch = async () => {
  const { data } = await axios.get("/accessEntryUser");

  return data;
};

const MapMonitoring = () => {
  const [filteredData, setFilteredData] = useState([]);

  const searchShowSettings = useMemo(
    () => ({
      switchNumber: true,
      location: true,
    }),
    []
  );

  const { data: entrySwitch, isLoading } = useQuery(
    "entrySwitch",
    fetchEntrySwitch
  );

  const handleSearchResults = useCallback((results) => {
    if (results.length === 0) {
      alert("검색 결과가 없습니다.");
    }
    setFilteredData(results);
  }, []);

  const TableShowSettingsLift = useMemo(
    () => ({
      number: true,
      switchNumber: true,
      location: true,
      changeTime: true,
      status: true,
    }),
    []
  );

  const TableShowSettingsRight = useMemo(
    () => ({
      number: true,
      light: true,
      fan: true,
      thermometer: true,
      hygrometer: true,
      powerMeter: true,
    }),
    []
  );

  return (
    <div>
      <Title title={"지도 모니터링"} data={entrySwitch} />
      <Search
        show={searchShowSettings}
        data={entrySwitch}
        onSearchResults={handleSearchResults}
      />
      <NaverMap data={entrySwitch} />
      <div className={styles.half}>
        <Table
          show={TableShowSettingsLift}
          data={filteredData.length > 0 ? filteredData : entrySwitch}
          isLoading={isLoading}
        />
        <Table
          show={TableShowSettingsRight}
          data={filteredData.length > 0 ? filteredData : entrySwitch}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default MapMonitoring;
