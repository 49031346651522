import { useState } from "react";
import axios from "axios";

const useCreateVerify = () => {
  const [errors, setErrors] = useState({});

  const createVerifyRules = {
    userId: {
      regex: /^[a-z0-9]{5,20}$/,
      errorMessage: "아이디는 5~20자의 영문 소문자와 숫자만 사용 가능합니다.",
      isDuplicate: true,
    },
    employeeNumber: {
      regex: /.+/,
      errorMessage: "사원번호를 입력해주세요.",
      // regex: /^[0-9]{4,10}$/,
      // errorMessage: "사원번호는 4~10자의 숫자만 사용 가능합니다.",
      isDuplicate: true,
    },
    phoneNumber: {
      regex: /^\d{10,11}$/,
      errorMessage: "휴대전화번호가 정확한지 확인해 주세요.",
      isDuplicate: true,
    },
    password: {
      regex: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/,
      errorMessage:
        "비밀번호는 8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.",
    },
    company: {
      regex: /.+/,
      errorMessage: "회사명을 입력해 주세요.",
    },
  };

  const createVerifyField = async (field, value, options = {}) => {
    const rules = createVerifyRules[field];
    if (!rules) return true;

    const { regex, errorMessage, isDuplicate } = rules;

    // 유효성 검사
    if (options.validate && !regex.test(value)) {
      setErrors((prev) => ({ ...prev, [field]: errorMessage }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }

    if (options.duplicate && isDuplicate) {
      try {
        const response = await axios.post("/entry/checkUser", {
          field,
          value,
          excludeUserId: options.excludeUserId,
          companyName: options.companyName,
        });

        if (response.data.isDuplicate) {
          const userKoreaFieldName =
            {
              userId: "아이디",
              employeeNumber: "사원번호",
              phoneNumber: "휴대전화번호",
            }[field] || field;
          setErrors((prev) => ({
            ...prev,
            [field]: `이미 등록된 ${userKoreaFieldName}입니다.`,
          }));

          return false;
        }
      } catch (error) {
        console.error("중복 검사 중 오류 발생:", error);
        setErrors((prev) => ({
          ...prev,
          [field]: "네트워크 오류가 발생했습니다. 나중에 다시 시도해주세요.",
        }));
        return false;
      }
    }

    return true;
  };

  return { errors, createVerifyField };
};

export default useCreateVerify;
