import styled from "styled-components";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import SwitchManagement from "./pages/SwitchManagement/SwitchManagement";
import MapMonitoring from "./pages/MapMonitoring/MapMonitoring";
import EntryMonitoring from "./pages/EntryMonitoring/EntryMonitoring";
import EntryHistory from "./pages/EntryHistory/EntryHistory";
import EntryAccessManagement from "./pages/EntryAccessManagement/EntryAccessManagement";
import EntryManagement from "./pages/EntryManagement/EntryManagement";
import Layout from "./components/Layout/Layout";
import AppLayout from "./components/Layout/AppLayout";
import Signup from "./pages/Signup/Signup";
import ToastProvider from "./components/ToastProvider/ToastProvider";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import Testing from "./pages/Testing/Testing";
import AppEntryUserLogin from "./pages/AppEntryUserLogin/AppEntryUserLogin";
import AppEntryUserMain from "./pages/AppEntryUserMain/AppEntryUserMain";
import AppEntryUserProfile from "./pages/AppEntryUserProfile/AppEntryUserProfile";

const Main = styled.div`
  min-height: 100vh;
  position: relative;
  flex: 7;
`;

const App = () => {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Main>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route path="/login" index element={<Login />} />
            <Route path="/app-login" index element={<AppEntryUserLogin />} />
            <Route path="/signup" index element={<Signup />} />
            <Route path="/testing" index element={<Testing />} />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/switch-management"
                element={
                  <Layout>
                    <SwitchManagement />
                  </Layout>
                }
              />
              <Route
                path="/entry-management"
                element={
                  <Layout>
                    <EntryManagement />
                  </Layout>
                }
              />
              <Route
                path="/entry-access-management"
                element={
                  <Layout>
                    <EntryAccessManagement />
                  </Layout>
                }
              />
              <Route
                path="/entry-history"
                element={
                  <Layout>
                    <EntryHistory />
                  </Layout>
                }
              />
              <Route
                path="/entry-monitoring"
                element={
                  <Layout>
                    <EntryMonitoring />
                  </Layout>
                }
              />
              <Route
                path="/map-monitoring"
                element={
                  <Layout>
                    <MapMonitoring />
                  </Layout>
                }
              />
              <Route
                path="/app-main"
                element={
                  <AppLayout>
                    <AppEntryUserMain />
                  </AppLayout>
                }
              />
              <Route
                path="/app-profile"
                element={
                  <AppLayout>
                    <AppEntryUserProfile />
                  </AppLayout>
                }
              />
            </Route>
          </Routes>
        </Main>
      </ToastProvider>
    </BrowserRouter>
  );
};

export default App;
