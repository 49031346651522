// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenu_userBtn__NqN5P {
  display: flex;
  color: #fff;
  align-items: center;
}
.UserMenu_userBtn__NqN5P li {
  padding: 1rem 0.5rem;
}
.UserMenu_userBtn__NqN5P li p {
  display: inline;
  font-size: 0.875rem;
  color: #fff;
}
.UserMenu_userBtn__NqN5P li:last-child {
  cursor: pointer;
}

@media (max-width: 1150px) {
  .UserMenu_userBtn__NqN5P li {
    padding: 0.85rem 0.4rem;
  }
  .UserMenu_userBtn__NqN5P li p {
    font-size: 0.85rem;
  }
}
@media (max-width: 1050px) {
  .UserMenu_userBtn__NqN5P li {
    padding: 0.75rem 0.35rem;
  }
  .UserMenu_userBtn__NqN5P li p {
    font-size: 0.7rem;
  }
}
@media (max-width: 970px) {
  .UserMenu_userBtn__NqN5P li {
    padding: 0.65rem 0.15rem;
  }
  .UserMenu_userBtn__NqN5P li p {
    font-size: 0.6rem;
  }
}
@media (max-width: 800px) {
  .UserMenu_userBtn__NqN5P {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/module/UserMenu/UserMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;AACF;AAAE;EACE,oBAAA;AAEJ;AAAI;EACE,eAAA;EACA,mBAAA;EACA,WAAA;AAEN;AAAI;EACE,eAAA;AAEN;;AAGA;EAEI;IACE,uBAAA;EADJ;EAGI;IACE,kBAAA;EADN;AACF;AAMA;EAEI;IACE,wBAAA;EALJ;EAOI;IACE,iBAAA;EALN;AACF;AAUA;EAEI;IACE,wBAAA;EATJ;EAWI;IACE,iBAAA;EATN;AACF;AAcA;EACE;IACE,aAAA;EAZF;AACF","sourcesContent":[".userBtn {\r\n  display: flex;\r\n  color: #fff;\r\n  align-items: center;\r\n  li {\r\n    padding: 1rem 0.5rem;\r\n\r\n    p {\r\n      display: inline;\r\n      font-size: 0.875rem;\r\n      color: #fff;\r\n    }\r\n    &:last-child {\r\n      cursor: pointer;\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 1150px) {\r\n  .userBtn {\r\n    li {\r\n      padding: 0.85rem 0.4rem;\r\n\r\n      p {\r\n        font-size: 0.85rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 1050px) {\r\n  .userBtn {\r\n    li {\r\n      padding: 0.75rem 0.35rem;\r\n\r\n      p {\r\n        font-size: 0.7rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 970px) {\r\n  .userBtn {\r\n    li {\r\n      padding: 0.65rem 0.15rem;\r\n\r\n      p {\r\n        font-size: 0.6rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 800px) {\r\n  .userBtn {\r\n    display: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userBtn": `UserMenu_userBtn__NqN5P`
};
export default ___CSS_LOADER_EXPORT___;
