// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Status_container__7s40u {
  text-transform: uppercase;
  color: #fff;
}
.Status_container__7s40u .Status_open__KokvK {
  background-color: green;
}
.Status_container__7s40u .Status_close__NAlYv {
  background-color: red;
}
.Status_container__7s40u .Status_workIn__SA5wq {
  background-color: #000000;
  color: rgb(255, 255, 255);
}
.Status_container__7s40u .Status_workOut__A9vd0 {
  background-color: #7a7a7a;
  color: rgb(207, 207, 207);
}`, "",{"version":3,"sources":["webpack://./src/components/Table/module/Status/Status.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;AACF;AACE;EACE,uBAAA;AACJ;AAEE;EACE,qBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,yBAAA;AADJ;AAIE;EACE,yBAAA;EACA,yBAAA;AAFJ","sourcesContent":[".container {\r\n  text-transform: uppercase;\r\n  color: #fff;\r\n\r\n  .open {\r\n    background-color: green;\r\n  }\r\n\r\n  .close {\r\n    background-color: red;\r\n  }\r\n\r\n  .workIn {\r\n    background-color: #000000;\r\n    color: rgb(255, 255, 255);\r\n  }\r\n\r\n  .workOut {\r\n    background-color: #7a7a7a;\r\n    color: rgb(207, 207, 207);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Status_container__7s40u`,
	"open": `Status_open__KokvK`,
	"close": `Status_close__NAlYv`,
	"workIn": `Status_workIn__SA5wq`,
	"workOut": `Status_workOut__A9vd0`
};
export default ___CSS_LOADER_EXPORT___;
