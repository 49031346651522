const menuItems = [
  { name: "개폐기관리", to: "/switch-management" },
  { name: "출입자관리", to: "/entry-management" },
  { name: "출입자권한관리", to: "/entry-access-management" },
  { name: "출입 내역조회", to: "/entry-history" },
  { name: "출입 모니터링", to: "/entry-monitoring" },
  { name: "지도 모니터링", to: "/map-monitoring" },
];

export default menuItems;
