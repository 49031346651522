import React, { useEffect, useState } from "react";
import axios from "axios";
import { client, utils } from "@passwordless-id/webauthn";
import useLogout from "../../hooks/useLogout/useLogout";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import styles from "./AppEntryUserProfile.module.scss";
import CompanyImg from "../../assets/images/company.png";
import UserImg from "../../assets/images/user.png";

const AppEntryUserProfile = () => {
  const logout = useLogout();
  const [credentialID, setCredentialID] = useState("");
  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (currentUser && currentUser.data && currentUser.data.webauthnDatas) {
      setCredentialID(currentUser.data.webauthnDatas.id);
    }
  }, [currentUser]);

  const { company, name } = currentUser.data;

  const handleRegister = async () => {
    try {
      const challenge = utils.randomChallenge();

      const registration = await client.register(
        currentUser.data.name,
        challenge,
        {
          authenticatorType: "auto",
          userVerification: "required",
          timeout: 60000,
          attestation: false,
          userHandle: "recommended to set it to a random 64 bytes value",
          debug: false,
        }
      );

      await axios
        .post("/entry/registration", {
          registration,
          challenge,
        })
        .then((res) => {
          setCredentialID(res.data.verify.credential.id);
          // console.log(res.data.verify.credential.id);
        });

      toast.success("등록 되었습니다.");
    } catch (error) {
      console.error(error);
    }
  };

  const handleAuthentication = async () => {
    try {
      const challenge = utils.randomChallenge();

      const authentication = await client.authenticate(
        [credentialID],
        challenge,
        {
          authenticatorType: "auto",
          userVerification: "required",
          timeout: 60000,
        }
      );

      await axios.post("/entry/authentication", {
        authentication,
        challenge,
      });

      toast.success("인증 되었습니다.");
    } catch (error) {
      console.error(error);
    }
  };

  const test = async () => {
    await axios.get("/entry/test", {}).then((res) => {
      console.log(res.data);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.navBar}>
          <a href="/app-main">
            <img src={CompanyImg} alt="" />
          </a>
          <div className={styles.profile}>
            <img className={styles.profileImage} src={UserImg} alt="" />
          </div>
        </div>
        <div className={styles.profileContainer}>
          <div className={styles.profile}>
            <img className={styles.profileImage} src={UserImg} alt="" />
            <span>
              {company} {name}
            </span>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={handleRegister}>
              지문 등록
            </button>
            <button className={styles.button} onClick={handleAuthentication}>
              지문 인증
            </button>
            <button className={styles.button} onClick={test}>
              테스트
            </button>
            <button className={styles.button} onClick={logout}>
              로그아웃
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppEntryUserProfile;
