const headers = [
  { id: "number", name: "번호" },
  { id: "switchNumber", name: "개폐기 번호" },
  { id: "location", name: "장소" },
  { id: "light", name: "조명 유무" },
  { id: "fan", name: "송풍기 유무" },
  { id: "thermometer", name: "온도계 유무" },
  { id: "hygrometer", name: "습도계 유무" },
  { id: "powerMeter", name: "전력 계량기 유무" },
  { id: "company", name: "업체" },
  { id: "employeeNumber", name: "사원번호" },
  { id: "name", name: "사원명" },
  { id: "userId", name: "아이디" },
  { id: "phoneNumber", name: "핸드폰번호" },
  { id: "accessStartDate", name: "출입가능시작일" },
  { id: "accessEndDate", name: "출입가능마지막일" },
  { id: "changeTime", name: "변경시간" },
  { id: "status", name: "상태" },
  { id: "temperatureHumidityPower", name: "온/습도 전력량" },
  { id: "registrationDate", name: "등록일" },
  { id: "editDelete", name: "수정/삭제" },
  { id: "accessPermissionHistory", name: "출입자권한내역" },
  { id: "viewLocation", name: "위치보기" },
  { id: "switchPermission", name: "개폐기권한" },
];

export default headers;
