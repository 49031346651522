import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.connect}>통신양호</div>
        <p className={styles.copyright}>
          Copyrights &copy; 2023 오토이노텍, All rights reserved.
        </p>
        <div></div>
      </div>
    </div>
  );
};

export default Footer;
