import React, { useEffect, useRef, useMemo } from "react";
import styles from "./NaverMapModal.module.scss";

const NaverMapModal = ({ data, onClose }) => {
  const mapRef = useRef(null);
  const { naver } = window;

  const LatLngClass = naver.maps.LatLng;
  const location = useMemo(
    () => new LatLngClass(data.position.longitude, data.position.latitude),
    [data.position.latitude, data.position.longitude, LatLngClass]
  );

  const mapOptions = useMemo(
    () => ({
      center: location,
      logoControl: false,
      mapDataControl: false,
      scaleControl: true,
      tileDuration: 200,
      zoom: 14,
      zoomControl: true,
      zoomControlOptions: { position: 9 },
    }),
    [location]
  );

  useEffect(() => {
    const map = new naver.maps.Map(mapRef.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map: map,
    });
  }, [location, mapOptions, naver.maps.Map, naver.maps.Marker]);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div
          id="map"
          ref={mapRef}
          style={{ width: "100%", height: "31.875rem" }}
        />
        <div className={styles.exitButton}>
          <button type="button" onClick={onClose}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default NaverMapModal;
