import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Title from "../../components/Title/Title";
import Search from "../../components/Search/Search";
import Table from "../../components/Table/Table";
import ExcelExportButton from "../../components/Table/module/ExcelExportButton/ExcelExportButton";
import axios from "axios";
import Loading from "../../components/Loading/Loading";

const fetchAccessEntryUser = async () => {
  const { data } = await axios.get("/accessEntryUser");

  return data;
};

const handleDeleteEntry = async (accessEntryUserId) => {
  await axios.delete(`/accessEntryUser/delete`, {
    data: { id: accessEntryUserId },
  });
};

const EntryAccessManagement = () => {
  const queryClient = useQueryClient();
  const [filteredData, setFilteredData] = useState([]);

  const SearchShowSettings = useMemo(
    () => ({
      accessEntryUserNumber: true,
      location: true,
      name: true,
      userId: true,
      phoneNumber: true,
      company: true,
    }),
    []
  );

  const TableShowSettings = useMemo(
    () => ({
      number: true,
      accessEntryUserNumber: true,
      location: true,
      company: true,
      employeeNumber: true,
      name: true,
      userId: true,
      phoneNumber: true,
      accessStartDate: true,
      accessEndDate: true,
      registrationDate: true,
      editDelete: true,
    }),
    []
  );

  const {
    data: accessEntryUserDatas,
    isLoading,
    isError,
  } = useQuery("accessEntryUsers", fetchAccessEntryUser, {});

  const handleSearchResults = useCallback((results) => {
    if (results.length === 0) {
      alert("검색 결과가 없습니다.");
    }
    setFilteredData(results);
  }, []);

  const deleteMutation = useMutation(handleDeleteEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries("accessEntryUsers");
    },
  });
  const handleDelete = (accessEntryUserId) => {
    deleteMutation.mutate(accessEntryUserId);
  };

  if (isLoading) return <Loading />;
  if (isError) return <span>에러가 발생했습니다.</span>;

  return (
    <div>
      <Title
        title={"출입자 권한 관리"}
        button1={
          <ExcelExportButton
            data={filteredData.length > 0 ? filteredData : accessEntryUserDatas}
            fileName="출입자권한관리"
          >
            출입자권한 엑셀출력
          </ExcelExportButton>
        }
      />
      <Search
        show={SearchShowSettings}
        data={accessEntryUserDatas}
        onSearchResults={handleSearchResults}
      />

      <Table
        show={TableShowSettings}
        data={filteredData.length > 0 ? filteredData : accessEntryUserDatas}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default EntryAccessManagement;
