// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NaverMapModal_modal__-\\+cQ7 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 60%;
  height: 65%;
  display: flex;
  flex-direction: column;
}
.NaverMapModal_modal__-\\+cQ7 .NaverMapModal_modalContent__hvjVw {
  width: 100%;
  overflow-x: auto;
}
.NaverMapModal_modal__-\\+cQ7 .NaverMapModal_modalContent__hvjVw .NaverMapModal_exitButton__qwCz7 {
  width: 100%;
}
.NaverMapModal_modal__-\\+cQ7 .NaverMapModal_modalContent__hvjVw .NaverMapModal_exitButton__qwCz7 button {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 55px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #667488;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.NaverMapModal_modal__-\\+cQ7 .NaverMapModal_modalContent__hvjVw .NaverMapModal_exitButton__qwCz7 button:hover,
.NaverMapModal_modal__-\\+cQ7 .NaverMapModal_modalContent__hvjVw .NaverMapModal_exitButton__qwCz7 button:active {
  background-color: #47515f;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/NaverMapModal/NaverMapModal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,uCAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,gBAAA;AACJ;AACI;EACE,WAAA;AACN;AACM;EACE,eAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,cAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,sCAAA;AACR;AAEM;;EAEE,yBAAA;AAAR","sourcesContent":[".modal {\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  background-color: #fff;\r\n  padding: 20px;\r\n  border-radius: 4px;\r\n  z-index: 1000;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  width: 60%;\r\n  height: 65%;\r\n  display: flex;\r\n  flex-direction: column;\r\n\r\n  .modalContent {\r\n    width: 100%;\r\n    overflow-x: auto;\r\n\r\n    .exitButton {\r\n      width: 100%;\r\n\r\n      button {\r\n        position: fixed;\r\n        bottom: 10px;\r\n        left: 0;\r\n        right: 0;\r\n        margin: 0 auto;\r\n        width: 55px;\r\n        padding: 8px 12px;\r\n        border: none;\r\n        border-radius: 4px;\r\n        background-color: #667488;\r\n        color: #fff;\r\n        cursor: pointer;\r\n        transition: background-color 0.3s ease;\r\n      }\r\n\r\n      button:hover,\r\n      button:active {\r\n        background-color: #47515f;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `NaverMapModal_modal__-+cQ7`,
	"modalContent": `NaverMapModal_modalContent__hvjVw`,
	"exitButton": `NaverMapModal_exitButton__qwCz7`
};
export default ___CSS_LOADER_EXPORT___;
