import React from "react";
import styles from "./Title.module.scss";
import { AiFillEnvironment } from "react-icons/ai";

const Title = ({ title, button1, button2 }) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <AiFillEnvironment />
        {<p className={styles.title}>{title}</p>}
      </div>
      <div className={styles.btnWarpper}>
        {button1 && button1}

        {button2 && (
          <button className={styles.button2} onClick={button2.onClick}>
            {button2.text}
          </button>
        )}
      </div>
    </div>
  );
};

export default Title;
