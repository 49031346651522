import React, { useState } from "react";
import axios from "axios";
import styles from "./Signup.module.scss";
import CompanyImg from "../../assets/images/company.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Signup = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const navigate = useNavigate();

  const handleSignup = (e) => {
    e.preventDefault();

    if (!userId || !password || !name || !phoneNumber || !company) {
      toast.error("모든 필드를 입력해주세요.");
      return;
    }

    axios({
      method: "post",
      url: "/auth/signup",
      data: {
        userId,
        password,
        name,
        phoneNumber,
        company,
      },
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("회원가입에 성공했습니다. 로그인 페이지로 이동합니다.");
          navigate("/login");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error("회원가입에 실패했습니다. 다시 시도해주세요.");
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={CompanyImg} alt="" />
        <div className={styles.main}>
          <form onSubmit={handleSignup}>
            <div className="inputWrapper">
              <div className="inputData">
                <label htmlFor="id">아이디</label>
                <input
                  type="text"
                  id="id"
                  placeholder="아이디"
                  required
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
              <div className="inputData">
                <label htmlFor="password">비밀번호</label>
                <input
                  type="password"
                  id="password"
                  autoComplete="off"
                  placeholder="비밀번호"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="inputData">
                <label htmlFor="name">이름</label>
                <input
                  type="text"
                  id="name"
                  placeholder="이름"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="inputData">
                <label htmlFor="phoneNumber">전화번호</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  placeholder="전화번호"
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="inputData">
                <label htmlFor="company">회사명</label>
                <input
                  type="text"
                  id="company"
                  placeholder="회사명"
                  required
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
            </div>
            <button type="submit">회원가입</button>
          </form>
          <p className={styles.copyright}>
            Copyrights &copy; 2023 <a href="/login">오토이노텍</a>, All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
