import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout as logoutAction } from "../../redux/userSlice";

const useLogout = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);

  console.log("hi..?");

  const handleLogout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      axios
        .post("/adminUser/logout", {}, { withCredentials: true })
        .then((res) => {
          console.log(res);

          dispatch(logoutAction());
          if (!currentUser?.data?.owner) {
            window.location.replace("/login");
          } else {
            window.location.replace("/app-login");
          }
        })
        .catch((error) => {
          console.error("Error during logout:", error);
        });
    }
  };

  return handleLogout;
};

export default useLogout;
