import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import styles from "./UpdateSwitchModal.module.scss";
import axios from "axios";

const UpdateSwitchModal = ({ onClose, data, onUpdate }) => {
  const [formData, setFormData] = useState({
    switchNumber: data?.switchNumber || "",
    location: data?.location || "",
    bluetoothId: data?.bluetoothId || "",
    authKey: data?.authKey || "",
    light: data?.light || false,
    fan: data?.fan || false,
    thermometer: data?.thermometer || false,
    hygrometer: data?.hygrometer || false,
    electricity: data?.electricity || false,
    address: data?.address || "",
    position: data?.position || { longitude: "", latitude: "" },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put("/switch/update", formData);
      const updatedSwitch = response.data;
      onUpdate(updatedSwitch);
      onClose(updatedSwitch);
      toast.success("개폐기 정보가 성공적으로 업데이트 되었습니다.");
    } catch (error) {
      console.log(error);
      toast.error("업데이트를 실패했습니다. 다시 시도해주세요.");
    }
  };

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        position: data.position || { longitude: "", latitude: "" },
      });
    }
  }, [data]);

  const handleInputChange = (event, type) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "boolean" ? value === "true" : value,
      position: {
        ...prevFormData.position,
        ...((name === "longitude" || name === "latitude") && { [name]: value }),
      },
    }));
  };

  return (
    <div className={styles.modal}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formDatas}>
          <label htmlFor="switchNumber">개폐기 번호:</label>
          <input
            id="switchNumber"
            type="text"
            name="switchNumber"
            value={formData.switchNumber || ""}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="location">장소:</label>
          <input
            id="location"
            type="text"
            name="location"
            value={formData.location || ""}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="bluetoothId">블루투스 ID:</label>
          <input
            id="bluetoothId"
            type="text"
            name="bluetoothId"
            value={formData.bluetoothId || ""}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="authKey">인증키:</label>
          <input
            id="authKey"
            type="text"
            name="authKey"
            value={formData.authKey || ""}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="address">주소:</label>
          <input
            id="address"
            type="text"
            name="address"
            value={formData.address || ""}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="longitude">위치 (경도):</label>
          <input
            id="longitude"
            type="text"
            name="longitude"
            value={formData.position.longitude || ""}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="latitude">위치 (위도):</label>
          <input
            id="latitude"
            type="text"
            name="latitude"
            value={formData.position.latitude || ""}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="light">조명 유무 :</label>
          <div className={styles.radioBox}>
            <input
              id="light-yes"
              type="radio"
              name="light"
              value="true"
              checked={formData.light === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="light-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="light-no"
              type="radio"
              name="light"
              value="false"
              checked={formData.light === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="light-no" className={styles.customRadio}>
              무
            </label>
          </div>

          <label htmlFor="fan">송풍기 유무:</label>
          <div className={styles.radioBox}>
            <input
              id="fan-yes"
              type="radio"
              name="fan"
              value="true"
              checked={formData.fan === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="fan-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="fan-no"
              type="radio"
              name="fan"
              value="false"
              checked={formData.fan === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="fan-no" className={styles.customRadio}>
              무
            </label>
          </div>

          <label htmlFor="thermometer">온도계 유무:</label>
          <div className={styles.radioBox}>
            <input
              id="thermometer-yes"
              type="radio"
              name="thermometer"
              value="true"
              checked={formData.thermometer === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="thermometer-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="thermometer-no"
              type="radio"
              name="thermometer"
              value="false"
              checked={formData.thermometer === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="thermometer-no" className={styles.customRadio}>
              무
            </label>
          </div>

          <label htmlFor="hygrometer">습도계 유무:</label>
          <div className={styles.radioBox}>
            <input
              id="hygrometer-yes"
              type="radio"
              name="hygrometer"
              value="true"
              checked={formData.hygrometer === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="hygrometer-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="hygrometer-no"
              type="radio"
              name="hygrometer"
              value="false"
              checked={formData.hygrometer === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="hygrometer-no" className={styles.customRadio}>
              무
            </label>
          </div>

          <label htmlFor="electricity">전기 계량기 유무:</label>
          <div className={styles.radioBox}>
            <input
              id="electricity-yes"
              type="radio"
              name="electricity"
              value="true"
              checked={formData.electricity === true}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="electricity-yes" className={styles.customRadio}>
              유
            </label>

            <input
              id="electricity-no"
              type="radio"
              name="electricity"
              value="false"
              checked={formData.electricity === false}
              onChange={(e) => handleInputChange(e, "boolean")}
              className={styles.hiddenRadio}
              required
            />
            <label htmlFor="electricity-no" className={styles.customRadio}>
              무
            </label>
          </div>
        </div>

        <div className={styles.buttons}>
          <button type="submit">확인</button>
          <button type="button" onClick={onClose}>
            닫기
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateSwitchModal;
