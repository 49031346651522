import React from "react";
import useLogout from "../../../../hooks/useLogout/useLogout";
import styles from "./UserMenu.module.scss";

const UserMenu = ({ userName }) => {
  const logout = useLogout();

  return (
    <ul className={styles.userBtn}>
      <li>
        <p>{userName}님이 로그인하셨습니다.</p>
      </li>
      <li>
        <p onClick={logout}>로그아웃</p>
      </li>
    </ul>
  );
};

export default UserMenu;
