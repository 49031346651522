import React, { useMemo } from "react";
import styles from "./SwitchListModal.module.scss";
import { useAccessEntryUseryData } from "../../../hooks/useAccessEntryUseryData/useAccessEntryUseryData";

const SwitchListModal = ({ onClose, data }) => {
  const {
    data: accessEntryUseryData,
    isLoading,
    isError,
  } = useAccessEntryUseryData();

  const filteredData = useMemo(() => {
    if (!isLoading && !isError && data.accessEntryUserId) {
      return accessEntryUseryData.filter((entry) => {
        return data.accessEntryUserId.includes(entry._id);
      });
    }
    return [];
  }, [accessEntryUseryData, isLoading, isError, data.accessEntryUserId]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <table>
          <thead>
            <tr>
              <th>순번</th>
              <th>사원번호</th>
              <th>아이디</th>
              <th>사원명</th>
              <th className={styles.registrationDate}>계정 생성일자</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((entry, index) => (
              <tr key={entry._id}>
                <td>{index + 1}</td>
                <td>{entry.employeeNumber}</td>
                <td>{entry.userId}</td>
                <td>{entry.name}</td>
                <td>{entry.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.exitButton}>
          <button type="button" onClick={onClose}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default SwitchListModal;
