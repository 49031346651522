// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_container__Hw387 {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.Footer_container__Hw387 .Footer_wrapper__2yd5a {
  height: 100%;
  width: 100%;
  height: 1.875rem;
  display: flex;
  justify-content: space-between;
  background-color: #667488;
  align-items: center;
}
.Footer_container__Hw387 .Footer_wrapper__2yd5a .Footer_connect__VYTdf {
  color: #00e200;
  top: 0;
  left: 0;
  font-size: 0.85rem;
}
.Footer_container__Hw387 .Footer_wrapper__2yd5a .Footer_copyright__lnOZY {
  text-transform: uppercase;
  color: #fff;
  font-size: 0.85rem;
}

@media (max-width: 450px) {
  .Footer_container__Hw387 .Footer_wrapper__2yd5a .Footer_connect__VYTdf,
  .Footer_container__Hw387 .Footer_wrapper__2yd5a .Footer_copyright__lnOZY {
    font-size: 0.688rem;
  }
}
@media (max-width: 326px) {
  .Footer_container__Hw387 .Footer_wrapper__2yd5a .Footer_connect__VYTdf,
  .Footer_container__Hw387 .Footer_wrapper__2yd5a .Footer_copyright__lnOZY {
    font-size: 0.531rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,yBAAA;EACA,mBAAA;AAEJ;AAAI;EACE,cAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;AAEN;AACI;EACE,yBAAA;EACA,WAAA;EACA,kBAAA;AACN;;AAIA;EAGM;;IAEE,mBAAA;EAHN;AACF;AAQA;EAGM;;IAEE,mBAAA;EARN;AACF","sourcesContent":[".container {\r\n  width: 100%;\r\n  position: absolute;\r\n  bottom: 0;\r\n  .wrapper {\r\n    height: 100%;\r\n    width: 100%;\r\n    height: 1.875rem;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    background-color: #667488;\r\n    align-items: center;\r\n\r\n    .connect {\r\n      color: #00e200;\r\n      top: 0;\r\n      left: 0;\r\n      font-size: 0.85rem;\r\n    }\r\n\r\n    .copyright {\r\n      text-transform: uppercase;\r\n      color: #fff;\r\n      font-size: 0.85rem;\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 450px) {\r\n  .container {\r\n    .wrapper {\r\n      .connect,\r\n      .copyright {\r\n        font-size: 0.688rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 326px) {\r\n  .container {\r\n    .wrapper {\r\n      .connect,\r\n      .copyright {\r\n        font-size: 0.531rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Footer_container__Hw387`,
	"wrapper": `Footer_wrapper__2yd5a`,
	"connect": `Footer_connect__VYTdf`,
	"copyright": `Footer_copyright__lnOZY`
};
export default ___CSS_LOADER_EXPORT___;
